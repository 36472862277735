import React from "react";
import classnames from "classnames";
import { setParams } from "../../utils/location";
import styles from "./index.module.scss";
import ExtrenalLink from "../ExternalLink";
import AppContainer from "../AppContainer";
import AppfrontIcon from "../icons/Appfront.svg";

export default ({ T, appStyles = {}, kioskMode, backPath, params }) => {
  const { links = {}, rtl, BrandFooter = {}, hiddenLinks = {} } = appStyles;

  return (
    <div
      className={styles.BrandFooter}
      style={{
        backgroundColor: appStyles.cardBackgroundColor,
        ...BrandFooter,
      }}
    >
      <ExtrenalLink
        link="https://appfront.ai/?src=pwrdby"
        title="Online Ordering, Loyalty, Rewards & CRM"
        appStyles={appStyles}
      >
        <span className={classnames(styles.PoweredBy, rtl && styles.RTL)}>
          Powered by <AppfrontIcon />
        </span>
      </ExtrenalLink>

      {!hiddenLinks.tos && (
        <ExtrenalLink
          link={links.tos}
          fallbackLink={setParams("/tos", { ...params, backPath })}
          appStyles={appStyles}
          style={{ color: appStyles.footerTextColor }}
        >
          <span className={styles.TOS}>{T("Terms of Service")}</span>
        </ExtrenalLink>
      )}
      {!hiddenLinks.privacy && (
        <ExtrenalLink
          link={links.privacy}
          fallbackLink={setParams("/privacy-policy", {
            ...params,
            backPath,
          })}
          appStyles={appStyles}
          style={{ color: appStyles.footerTextColor }}
        >
          <span className={styles.TOS}>{T("Privacy Policy")}</span>
        </ExtrenalLink>
      )}

      <span
        className={styles.Copyright}
        style={{ color: appStyles.footerTextColor }}
      >
        © {T(`All rights reserved to {{business_name}}`)}
      </span>
    </div>
  );
};
