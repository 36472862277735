import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { get, includes, find, isEmpty, map, pick } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import AppContainer from "../AppContainer";
import BackgroundImage from "../BackgroundImage";
import HomeTitle from "../HomeTitle";
import BrandFooter from "../BrandFooter";
import HomeButtons from "./HomeButtons";
import Button from "../Button";
import BackgroundImageExtrnalLink from "../BackgroundImageExtrnalLink";
import NewOrPreviousOrderModal from "../NewOrPreviousOrderModal";
import InfoIcon from "../icons/Info.svg";
import TableIcon from "../icons/Table.svg";
import LearnMoreIcon from "../icons/LearnMore.svg";
import { navigateTo, getParams, setParams } from "../../utils/location";
import styles from "./index.module.scss";
import { getAppMedia, mediaTypes } from "../../utils/media";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LABELS from "../labels";
import { Box, Grid, Drawer } from "@material-ui/core";
import Promotion from "../promotion";
import PromotionsAvatars from "../promotion/promotions-avatars";

export default function IndexView({
  startNewOrder,
  order,
  T,
  appStyles,
  promotions,
  pageContext: { servingOptions, branches, businessAppConfiguration },
  user,
  location,
  openAuthModal,
  openMobileAuthView,
}) {
  const classes = useStyles();
  const [startNewOrderModalOpen, setStartNewOrderModalOpen] = useState(false);
  const [openDesktopPromotions, setOpenDesktopPromotions] = useState(false);
  const handleOpenPromotions = () => setOpenDesktopPromotions(true);
  const handleClosePromotions = () => setOpenDesktopPromotions(false);

  const { orderItems, servingOptionType, branchId } = order;

  const navigateToTargetOrLoginMobile = useCallback(
    ({ type, target }) => () => {
      user.loggedIn ? navigateTo(target) : openMobileAuthView(type);
    },
    [openMobileAuthView, user.loggedIn],
  );

  const navigateToTargetOrLoginDesktop = useCallback(
    ({ type, target }) => () => {
      user.loggedIn ? navigateTo(target) : openAuthModal(type);
    },
    [openAuthModal, user.loggedIn],
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const navigateToNewOrder = useCallback(
    () =>
      navigateTo(
        servingOptions.length < 2 ? "/find-location" : "/serving-options",
      ),
    [],
  );

  const handleStartNewOrderClicked = useCallback(
    (e) => {
      e.preventDefault();
      const isDuringOrder = !isEmpty(orderItems);
      if (isDuringOrder) {
        setStartNewOrderModalOpen(true);
      } else {
        startNewOrder();
        navigateToNewOrder();
      }
    },
    [orderItems],
  );

  const closeStartNewOrderModal = useCallback(
    () => setStartNewOrderModalOpen(false),
    [],
  );

  const onUserConfirmStartNewOrder = useCallback(() => {
    closeStartNewOrderModal();
    startNewOrder();
    navigateToNewOrder();
  }, [startNewOrder]);

  const continueOrder = useCallback(() => {
    closeStartNewOrderModal();
    if (order.branchId) {
      navigateTo(
        setParams("/order", pick(order, ["branchId, servingOptionType"])),
      );
    } else if (order.servingOptionType) {
      navigateTo(
        setParams("/find-location", pick(order, ["servingOptionType"])),
      );
    } else {
      navigateToNewOrder();
    }
  }, [order.branchId, order.servingOptionType]);

  const disableRewards =
    get(businessAppConfiguration, "disableRewards") &&
    !appStyles.rewardsAlternative;
  const enableFeedback = get(businessAppConfiguration, "enableFeedback");
  const requireLoginForGiftCard = get(
    businessAppConfiguration,
    "requireLoginForGiftCard",
  );
  const giftCardsEnabled = get(businessAppConfiguration, "giftCardsEnabled");

  const showGiftCard = requireLoginForGiftCard
    ? user.loggedIn && giftCardsEnabled
    : giftCardsEnabled;

  const orderSelectedServingOption =
    find(servingOptions, { type: servingOptionType }) || {};
  const orderSelectedBranch = find(branches, { id: branchId }) || {};

  const { links = {} } = appStyles;

  const split = appStyles.homepageImageSplit;
  const tiles = isMobile && appStyles.homepageTileButtons;
  const webAppBackground =
    getAppMedia(mediaTypes.webAppBackground, appStyles) || {};

  const webMobileAppBackground =
    getAppMedia(mediaTypes.webMobileAppBackground, appStyles) ||
    webAppBackground ||
    {};

  const isSSR = typeof window === "undefined";

  const isNative = !isSSR && window.isNativeApp;

  const disableReorder = includes(appStyles.disabledFeatures, "reorder");
  const disableOrder = includes(appStyles.disabledFeatures, "order");
  const hasTwoButtons = disableRewards || (disableReorder && disableOrder);
  const hasScrollableHomepageLayout = appStyles.hasScrollableHomepageLayout;
  const backgroundImageHeight = getBackgroundImageHeight({
    split,
    tiles,
    isNative,
    hasTwoButtons,
    hasScrollableHomepageLayout,
  });

  const showMobilePromotions =
    hasScrollableHomepageLayout && isMobile && !isEmpty(promotions);

  const showDesktopPromotions =
    hasScrollableHomepageLayout && !isMobile && !isEmpty(promotions);

  return (
    <AppContainer.ResponsiveWrapper fullscreen>
      <AppContainer.Content appStyles={appStyles} classNames={styles.Content}>
        <NewOrPreviousOrderModal
          T={T}
          appStyles={appStyles}
          onChooseNewOrder={onUserConfirmStartNewOrder}
          onChooseContinueOrder={continueOrder}
          modalOpen={startNewOrderModalOpen}
          closeModal={closeStartNewOrderModal}
          orderSelectedServingOption={orderSelectedServingOption}
          orderSelectedBranch={orderSelectedBranch}
        />

        <HomeTitle
          T={T}
          title={T("Welcome to")}
          desktopExtraTitle={T("{{desktop_extra_title}}")}
          appStyles={appStyles}
          className={classnames(!split && enableFeedback && styles.HomeTitle)}
        />

        <BackgroundImage
          noAnimation
          mediaType={mediaTypes.webMobileAppBackground}
          mediaKey={webMobileAppBackground.imageKey}
          sizes="768px"
          imagePreview={webMobileAppBackground.imagePreview}
          classNames={styles.BackgroundImage}
          split={split}
          tiles={tiles}
          height={backgroundImageHeight}
        >
          <AppContainer.AttachedContent
            classNames={
              split ? styles.MobileBottomButtons : styles.TopSideMobileButtons
            }
          >
            {map(appStyles.externalLinks, (link) => (
              <Button
                slim
                centered
                appStyles={appStyles}
                isExternalLink
                to={link.href}
                classNames={styles.ButtonWithIcon}
              >
                {T(link.label)}
                {link.useLearnMoreIcon ? <LearnMoreIcon /> : <TableIcon />}
              </Button>
            ))}
            {enableFeedback &&
              (appStyles.contactUsAlternative ? (
                <Button
                  slim
                  centered
                  appStyles={appStyles}
                  isExternalLink
                  to={appStyles.contactUsAlternative.href}
                  // classNames={styles.ButtonWithIcon}
                >
                  {T(appStyles.contactUsAlternative.label || LABELS.CONTACT_US)}
                </Button>
              ) : (
                <Button appStyles={appStyles} to="/contact-us" centered slim>
                  {T(LABELS.CONTACT_US)}
                </Button>
              ))}
            {showGiftCard && (
              <Button
                appStyles={appStyles}
                to={setParams("/gift-card", { onCloseTarget: "/" })}
                centered
                slim
              >
                {T("Send a Gift Card")}
              </Button>
            )}
          </AppContainer.AttachedContent>
        </BackgroundImage>

        {!isSSR && (
          <HomeButtons
            T={T}
            isSSR={isSSR}
            showMobilePromotions={showMobilePromotions}
            appStyles={appStyles}
            loggedIn={user.loggedIn}
            handleStartNewOrderClicked={handleStartNewOrderClicked}
            isMobile={isMobile}
            disableRewards={disableRewards}
            amountOfGiftsUnseen={user.amountOfGiftsUnseen}
            navigateToTargetOrLoginDesktop={navigateToTargetOrLoginDesktop}
            navigateToTargetOrLoginMobile={navigateToTargetOrLoginMobile}
          />
        )}

        {showMobilePromotions && (
          <Box padding={2}>
            <Grid container direction="column" spacing={2}>
              {map(promotions, (promotion) => (
                <Grid item>
                  <Promotion
                    promotion={promotion}
                    appStyles={appStyles}
                    navigateToTargetOrLoginDesktop={
                      navigateToTargetOrLoginDesktop
                    }
                    navigateToTargetOrLoginMobile={
                      navigateToTargetOrLoginMobile
                    }
                    handleStartNewOrderClicked={handleStartNewOrderClicked}
                    T={T}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </AppContainer.Content>
      <AppContainer.SecondaryContent relative>
        <AppContainer.AttachedContent
          classNames={classnames(
            styles.TopSideButtons,
            appStyles.rtl && styles.RTL,
          )}
        >
          {enableFeedback &&
            (appStyles.contactUsAlternative ? (
              <BackgroundImageExtrnalLink
                appStyles={appStyles}
                slim
                to={appStyles.contactUsAlternative.href}
              >
                {T(appStyles.contactUsAlternative.label || LABELS.CONTACT_US)}
              </BackgroundImageExtrnalLink>
            ) : (
              <Button appStyles={appStyles} to="/contact-us" slim centered>
                {T(LABELS.CONTACT_US)}
              </Button>
            ))}

          {showGiftCard && (
            <Button
              appStyles={appStyles}
              to={setParams("/gift-card", { onCloseTarget: "/" })}
              centered
              slim
            >
              {T("Send a Gift Card")}
            </Button>
          )}

          {showDesktopPromotions && (
            <PromotionsAvatars
              open={openDesktopPromotions}
              promotions={promotions}
              onOpen={handleOpenPromotions}
              onClose={handleClosePromotions}
            />
          )}

          {showDesktopPromotions && (
            <Drawer
              className={classes.drawer}
              variant="persistent"
              classes={{
                paper: classes.drawerPaper,
              }}
              anchor="right"
              open={openDesktopPromotions}
            >
              <Box padding={2}>
                <Grid container direction="column" spacing={2}>
                  {map(promotions, (promotion) => (
                    <Grid item>
                      <Promotion
                        promotion={promotion}
                        appStyles={appStyles}
                        navigateToTargetOrLoginDesktop={
                          navigateToTargetOrLoginDesktop
                        }
                        navigateToTargetOrLoginMobile={
                          navigateToTargetOrLoginMobile
                        }
                        handleStartNewOrderClicked={handleStartNewOrderClicked}
                        T={T}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Drawer>
          )}
        </AppContainer.AttachedContent>

        {links.nutrition && (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            to={links.nutrition}
          >
            <InfoIcon />
            {T("See our nutrition facts")}
          </BackgroundImageExtrnalLink>
        )}
        {appStyles.scanAlternative && (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            to={appStyles.scanAlternative.href}
            bottom
            classNames={styles.ScanAlternative}
          >
            {appStyles.scanAlternative.useLearnMoreIcon ? (
              <LearnMoreIcon style={{ width: 37, height: "auto" }} />
            ) : (
              <TableIcon />
            )}{" "}
            {T(appStyles.scanAlternative.label)}
          </BackgroundImageExtrnalLink>
        )}
        {map(appStyles.externalLinks, (link) => (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            bottom
            to={link.href}
            classNames={styles.ScanAlternative}
          >
            {link.useLearnMoreIcon ? <LearnMoreIcon /> : <TableIcon />}
            {T(link.label)}
          </BackgroundImageExtrnalLink>
        ))}
        <BackgroundImage
          mediaType={mediaTypes.webAppBackground}
          mediaKey={webAppBackground.imageKey}
          sizes="1920px"
          imagePreview={webAppBackground.imagePreview}
          noAnimation
        />
      </AppContainer.SecondaryContent>
      <BrandFooter
        T={T}
        appStyles={appStyles}
        backPath="/"
        params={getParams(location)}
      />
    </AppContainer.ResponsiveWrapper>
  );
}

const getBackgroundImageHeight = ({
  split,
  tiles,
  isNative,
  hasTwoButtons,
  hasScrollableHomepageLayout,
}) => {
  if (hasScrollableHomepageLayout) {
    return "100vw";
  }
  if (!split) {
    return "calc(100% - 32px)";
  }
  if (split && tiles) {
    if (isNative) {
      return "calc(100% - 2 * (50vw - 2 * 32px) - 60px -  60px)";
    } else {
      return "calc(100% - 2 * (50vw - 2 * 32px) - 60px - 60px - 32px - 16px)";
    }
  }

  if (isNative) {
    return `calc(100% - 212px - 60px - 12px - 32px${
      hasTwoButtons ? " + 72px" : ""
    })`;
  } else {
    return `calc(100% - 212px - 60px - 12px - 60px${
      hasTwoButtons ? " + 72px" : ""
    })`;
  }
};

const drawerWidth = 376;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    position: "absolute",
    top: 120,
    height: `calc(100vh - 260px)`,
    borderLeft: "none",
    width: drawerWidth,
    backgroundColor: "transparent",
    WebkitMaskImage:
      "-webkit-gradient(linear, right 96%, right bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
  },
}));
