import React from "react";
import classnames from "classnames";
import { withPrefix } from "gatsby";
import { SocialIcon } from "react-social-icons";
import styles from "./index.module.scss";
import _ from "lodash";

export default ({
  title,
  appStyles = {},
  T,
  desktopExtraTitle,
  kioskTitle,
  className,
}) => {
  const {
    HomeFirstTitle,
    HomeMobileFirstTitle,
    HomeSecondTitle,
    HomeExtraTitlesDesktop,
    HomeMainTitleDesktop,
    HomeThirdTitle,
    DesktopTitleLogo,
    hompageTitleCentered: center,
  } = appStyles;

  const logoImage = _.get(DesktopTitleLogo, "backgroundImage");
  const { backgroundImage, ...logoImageProps } = DesktopTitleLogo || {};
  const logoBackground = logoImage && `url(${withPrefix(logoImage)})`;

  return (
    <div>
      <div
        className={classnames(
          className,
          styles.HomeTitle,
          center && styles.Center,
          styles.HideOnDesktop,
        )}
      >
        <span
          style={{
            ...(HomeMobileFirstTitle || HomeFirstTitle),
            marginLeft: 10,
          }}
        >
          {title}
        </span>
        <span style={HomeSecondTitle}>{T("{{business_name_title}}")}</span>
        <span style={HomeThirdTitle}>
          {T("{{business_name_second_title}}")}
        </span>
      </div>
      <div
        className={classnames(
          styles.HomeTitleDesktop,
          center && styles.Center,
          styles.HideOnMobile,
        )}
      >
        {DesktopTitleLogo && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                ...logoImageProps,
                backgroundImage: logoBackground,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
              }}
            />
          </div>
        )}
        {kioskTitle ? (
          <div
            className={styles.TitlePadding}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span style={{ ...HomeSecondTitle, marginBottom: 60 }}>
              {T("{{business_name_desktop}}")}
            </span>
            <span style={HomeMainTitleDesktop}>{kioskTitle}</span>
          </div>
        ) : appStyles.desktopTitleOnSeparateLine ? (
          <div className={classnames(styles.HomeTitleDesktop)}>
            <span style={HomeFirstTitle}>{T("{{desktop_title_prefix}}")}</span>
            <span style={HomeMainTitleDesktop}>
              {T("{{business_name_desktop}}")}
            </span>
            <span style={HomeThirdTitle}>{T("{{desktop_title_suffix}}")}</span>
          </div>
        ) : (
          <span
            style={HomeMainTitleDesktop}
            className={styles.TitlePadding}
          >{`${T("{{desktop_title_prefix}}")} ${T(
            "{{business_name_desktop}}",
          )}${
            _.startsWith(T("{{desktop_title_suffix}}"), "'")
              ? T("{{desktop_title_suffix}}")
              : ` ${T("{{desktop_title_suffix}}")}`
          }`}</span>
        )}
        <span
          style={{
            color: appStyles.accentColor,
            paddingLeft: 16,
            paddingRight: 16,
            ...HomeExtraTitlesDesktop,
          }}
        >
          {desktopExtraTitle}
        </span>
        {appStyles.socialLinks && (
          <div
            style={{
              paddingLeft: appStyles.alignSocialLinksToLeft ? 8 : 0,
              display: "flex",
              justifyContent: appStyles.alignSocialLinksToLeft
                ? "flex-start"
                : "center",
            }}
          >
            {_.map(
              _.filter(_.values(appStyles.socialLinks), _.negate(_.isEmpty)),
              (url) => (
                <SocialIcon
                  target="_blank"
                  url={url}
                  style={{ margin: 4, ...appStyles.SocialLink }}
                  fgColor="#fff"
                  bgColor={appStyles.actionColor}
                />
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
