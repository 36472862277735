import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import Image from "../image";
import castArray from "lodash/castArray";

export default ({
  mediaType,
  mediaKey,
  imagePreview,
  sizes,
  height = "100%",
  classNames,
  split,
  children,
  noAnimation,
}) =>
  mediaType && mediaKey ? (
    <div
      className={classnames(
        ...castArray(classNames),
        split
          ? styles.BackgroundImageWrapperSplit
          : styles.BackgroundImageWrapperFull,
      )}
      style={{ width: "100%", height }}
    >
      <Image
        noAnimation={noAnimation}
        className={classnames(styles.MobileBackgroundImage)}
        mediaType={mediaType}
        mediaKey={mediaKey}
        imagePreview={imagePreview}
        size={sizes}
        style={{ height: "100%", width: "100%" }}
      />
      {children}
    </div>
  ) : (
    <div
      className={classnames(
        ...castArray(classNames),
        split
          ? styles.BackgroundImageWrapperSplit
          : styles.BackgroundImageWrapperFull,
        styles.DefaultImage,
      )}
      style={{ width: "100%", height }}
    >
      {children}
    </div>
  );
