import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { navigateTo } from "../../utils/location";
import Button from "../Button";
import ServingOptionsIcon from "../serving-option-icon";
import styles from "./index.module.scss";

export default ({
  appStyles,
  T,
  onChooseNewOrder,
  onChooseContinueOrder,
  modalOpen,
  closeModal,
  orderSelectedServingOption,
  orderSelectedBranch,
}) => {
  const classes = useStyles();
  const servingOptionTag = _.toLower(
    orderSelectedServingOption.servingOptionTag,
  );
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      focusTrapped
      closeAfterTransition
    >
      <div
        className={classes.paper}
        style={{
          direction: appStyles.rtl && "rtl",
          ...(appStyles.bodyFont && { fontFamily: appStyles.bodyFont }),
          ...(appStyles.bodyFontWeight && {
            fontFamily: appStyles.bodyFontWeight,
          }),
          borderRadius: 5,
          color: _.get(appStyles, "Card.color", appStyles.textColor),
        }}
      >
        <div
          style={{ color: appStyles.actionColor }}
          className={styles.SVGWrapper}
        >
          {orderSelectedServingOption && orderSelectedServingOption.type && (
            <ServingOptionsIcon
              appStyles={appStyles}
              servingOption={orderSelectedServingOption.type}
            />
          )}
        </div>
        <div
          id="modal-title"
          style={{ textAlign: "center" }}
          role="heading"
          aria-level="1"
        >{`${T(
          `${T(`We noticed that you are ordering`)} ${servingOptionTag} ${T(
            "from",
          )}`,
        )} ${orderSelectedBranch.name}`}</div>
        <div
          style={{
            ...appStyles.StartNewOrderPopupTitle,
            textAlign: "center",
            margin: "16px 0",
          }}
          role="heading"
          id="modal-description"
          aria-level="2"
        >
          {T(`What would you like to do?`)}
        </div>
        <div>
          <Button
            appStyles={appStyles}
            centered
            style={{ marginBottom: 20 }}
            onClick={onChooseContinueOrder}
          >
            {T("Continue order")}
          </Button>
          <Button
            linkStyle
            appStyles={appStyles}
            style={{ paddingBottom: 10, paddingTop: 10 }}
            centered
            onClick={onChooseNewOrder}
          >
            {T("Start new order")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
