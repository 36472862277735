import React from "react";
import classnames from "classnames";
import openNativeLink from "../../utils/openNativeLink";

import styles from "./index.module.scss";

export default ({
  appStyles,
  style,
  classNames,
  to,
  linkStyle,
  children,
  bottom,
  slim,
}) => {
  const nativeMode = typeof window !== "undefined" ? window.isNativeApp : false;

  return (
    <a
      href={nativeMode ? "javascript;" : to}
      onClick={nativeMode && openNativeLink(to)}
      target={!nativeMode && "_blank"}
      style={{
        ...(linkStyle
          ? { background: "transparent", color: appStyles.actionColor }
          : { ...appStyles.Button, ...appStyles.ExternalLinkButton }),
        ...(slim && appStyles.SlimButton),
        ...style,
      }}
      className={classnames(
        styles.ExternalLinkButton,
        appStyles.rtl && styles.RTL,
        bottom && styles.Bottom,
        slim && styles.Slim,
        classNames,
      )}
    >
      {children}
    </a>
  );
};
